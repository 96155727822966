@use '@/styles/utils/mixins.scss' as *;

.legalDocumentsWrapper {
  display: flex;
  flex-direction: column;
  gap: 18.75rem;

  @include tab() {
    gap: 12.5rem;
  }

  .legalDocumentsCardsList {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;

    .legalDocumentsCard {
      background-color: $white;
      border-radius: 1.25rem;
      width: calc(33.33% - 2.5rem);
      padding: 2.5rem 1.875rem;
      display: flex;
      gap: 1.25rem;
      flex-direction: column;
      cursor: pointer;
      min-height: 15rem;
      -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
      -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
      box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
      color: $dark;
      transition: all 0.2s ease-in-out;

      @include tab() {
        width: calc(50% - 2.5rem);
      }
      @include tab() {
        margin: 0 -1.875rem;
        width: calc(100% + 3.75rem);
      }

      .legalDocumentsCardDescription {
        color: $neutral08;
        transition: all 0.2s ease-in-out;
        font-weight: 400;
      }

      :global {
        .titleMd {
          @include mob() {
            font-size: 1.5rem;
          }
        }
      }

      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: $primary;
        color: $white;
        .legalDocumentsCardDescription {
          color: $white;
        }
      }
    }
  }
  .legalDocumentsContentCard {
    background-color: $white;
    border-radius: 1.25rem;
    width: 100%;
    padding: 3.75rem;
    display: flex;
    gap: 3.75rem;
    flex-direction: column;
    -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
    -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
    box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
    transition: all 0.2s ease-in-out;
    color: $dark;
    font-weight: normal;
    @include tab() {
      margin: 0 -1.875rem;
      width: calc(100% + 3.75rem);
      padding: 3.75rem 1.875rem;
    }
  }
  .legalDocumentsContentBox {
    display: flex;
    gap: 3.75rem;
    flex-direction: column;
    @include tab() {
      gap: 2.5rem;
    }
    .contactContainer {
      display: flex;
      gap: 1.25rem;
      align-items: center;
      padding: 2.5rem 2rem;

      @include tab() {
        flex-direction: column;
        gap: 1.5rem;
        padding: 1.25rem 0;
      }

      .contactBox {
        display: flex;
        gap: 1.875rem;
        padding: 1rem 0;
        width: calc(50% - 1.25rem);
        align-items: first baseline;
        @include tab() {
          flex-direction: column;
          gap: 0.5rem;
          width: 100%;
          padding: 0;
        }

        &:not(:first-child) {
          padding-left: 1.25rem;
          border-left: 0.063rem solid $primaryAlt;
          @include tab() {
            padding-left: 0rem;
            border-left: 0rem solid $primaryAlt;
            padding-top: 1.5rem;
            border-top: 0.063rem solid $primaryAlt;
          }
        }

        .contactTitle {
          color: $primary;
          font-size: 1rem;
          text-transform: uppercase;
          width: fit-content;
          font-weight: 500;
        }
        .contactLinks {
          display: flex;
          flex-direction: column;
          font-size: 1.25rem;
          font-weight: 500;
          gap: 0.25rem;
          @include tab() {
            font-size: 1rem;
          }
          a {
            color: inherit;
            &:hover,
            &:focus,
            &:active {
              color: $primary;
            }
          }
        }
      }
    }
    .legalDocumentsContent {
      :global {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 500;
          margin-bottom: 0.625rem;
          &:not(:first-child) {
            margin-top: 2.5rem;
          }
        }
        h1 {
          font-size: 2.5rem;
        }
        h2 {
          font-size: 1.5rem;
        }
        h3 {
          font-size: 1rem;
          font-weight: bold;
        }

        a {
          color: $dark;
          transition: all 0.2s ease-in-out;
          &:hover,
          &:focus,
          &:active {
            color: $primary;
          }
        }
        p {
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
          max-width: 54.25rem;
        }
        ul,
        ol {
          padding-left: 1.5rem;
        }
      }
    }

    .documentsList {
      display: flex;
      flex-direction: column;
      gap: 1.875rem;
      max-width: 54.25rem;
      .documentItem {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        @include tab() {
          gap: 1.875rem;
        }
        &:not(:first-child) {
          :global {
            .headingElement {
              margin-top: 2.5rem;
              @include tab() {
                margin-top: 1.875rem;
              }
            }
          }
        }
        &Box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.75rem;
          width: 100%;
          border-radius: 1.25rem;
          padding: 1.25rem 1.875rem;
          background-color: $neutral02;

          @include tab() {
            flex-direction: column;
            align-items: flex-start;
          }
        }
        &Title {
          font-size: 1rem;
          font-weight: bold;
          color: $primary;
          margin-bottom: 0;
        }
        &DownloadSection {
          display: flex;
          align-items: center;
          gap: 1.25rem;
        }
        &DownloadLink {
          font-size: 1rem;
          font-weight: 500;
          color: $dark;
          &:hover,
          &:focus,
          &:active {
            color: $primary;
          }
        }
      }
    }
  }
}
